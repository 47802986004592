<template>
  <div class="box">
    <header>
      <router-link to="/jiyun_search">
        <img class="arrow-left" src="../../../src/assets/usian/arrow-right.png" />
      </router-link>
      <router-link
        :to="
          '/jiyun_china?provinceName=' +
            provinceName +
            '&provinceCode=' +
            provinceCode
        "
      >
        <img class="arrow-right" src="../../../src/assets/usian/arrow-right.png" />
      </router-link>
    </header>
    <div class="main">
      <div class="left">
        <!-- <h3 class="title">县级统计</h3>
        <div class="section-box">
          <div class="section" ref="country">
            <div>
              <div class="item" v-for="(item, index) in countries" :key="index">
                <h5>{{ item.name }}</h5>
                <div class="item-bg bg-country">
                  <h6>{{ item.studentNum }}</h6>
                </div>
              </div>
            </div>
          </div>
        </div>-->

        <!-- <h3 class="title">报名地点</h3>
        <div class="section-box">
          <div class="swiper" ref="baomingAddress">
            <div class="swiper-wrapper" ref="baomingAddressContent">
              <div class="item">
                <div class="item-bg bg-baoming">
                  <h6>{{ marketNumTotal }}</h6>
                  <p>总数</p>
                </div>
              </div>
              <div class="item" v-for="(item, index) in countries" :key="index">
                <div class="item-bg bg-baoming" @click="getAddress(index)">
                  <h6>{{ item.marketNum }}</h6>
                  <p>{{ item.name }}</p>
                </div>
              </div>
            </div>
          </div>

          <div class="swiper-content">
            <div
              class="c-item"
              v-for="(item, index) in baomingAddress"
              :key="index"
            >
              <span>{{ item.name }}</span>
              <span>{{ item.usertel ? item.usertel : "暂无数据" }}</span>
              <span>地址: {{ item.address ? item.address : "暂无数据" }}</span>
            </div>
            <div class="empty" v-if="baomingAddress.length == 0">
              <span>暂无数据</span>
            </div>
          </div>
        </div>-->

        <div>
          <h3 class="title">招生数据</h3>
          <div class="section-box">
            <div class="section" ref="zhaosheng"></div>
          </div>
        </div>

        <div>
          <h3 class="title">人数统计</h3>
          <div class="section-box">
            <div class="section renshu">
              <div class="s-item">
                <img src="../../../src/assets/usian/bg_circle.png" alt="圆圈背景" />
                <div>
                  <h3>在校人数</h3>
                  <h5>{{ studentNumTotal }}</h5>
                </div>
              </div>

              <div class="s-item">
                <img src="../../../src/assets/usian/bg_circle.png" alt="圆圈背景" />
                <div>
                  <h3>高薪人数</h3>
                  <h5>{{ employmentHighTotal }}</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="center" ref="provinceCanvas"></div>
      <div class="right">
        <div>
          <h3 class="title">就业喜报</h3>
          <div class="section-box">
            <div class="section swiper-container">
              <div class="jiuye-content swiper-wrapper">
                <div class="xibao swiper-slide" v-for="(item, index) in emploies" :key="index">
                  <span>{{ item.username }}</span>
                  <span>{{ item.official_salary_format }}</span>
                  <span class="corp">{{ item.company }}</span>
                  <span class="date">{{ item.entry_date }}</span>
                </div>
                <div class="empty" v-if="emploies.length == 0">
                  <span>暂无数据</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <h3 class="title">薪酬比例</h3>
          <div class="section-box">
            <div class="section" ref="xinchou"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper/js/swiper.min.js';
    import 'swiper/css/swiper.min.css';
// import BScroll from "better-scroll";
const axios = require("axios").default;
let myChart = null;
export default {
  data() {
    return {
      swiper: null, //就业滚动
      provinceName: "", //省市名称
      provinceCode: 0, //省代码
      cityCode: 0, //市代码
      countryCode: 0, //区代码
      mapJson: {}, //地图json
      mapData: [], //地图数组
      countries: [], //县级数据
      marketNumTotal: 0, //总的县办数量
      baomingAddress: [], //报名地址
      studentNumTotal: 0, //市总人数
      employmentHighTotal: 0, //高薪人数
      employmentNormalTotal: 0, //普薪人数
      preMonthNum: [], //最近一年招生数据
      emploies: [] //就业数据
    };
  },
  mounted() {
    this.provinceName = this.$route.query.provinceName;
    this.provinceCode = this.$route.query.provinceCode;
    this.initProvince();
  },
  methods: {
    getAddress(index) {
      this.countryCode = this.countries[index].adcode;
      this.getBaomingData();
    },
    initPie() {
      // 基于准备好的dom，初始化echarts实例
      var myChart = this.$echarts.init(this.$refs.xinchou);

      // 指定图表的配置项和数据
      var option = {
        tooltip: {},
        series: [
          {
            type: "pie",
            radius: "55%",
            data: [
              {
                value: this.employmentHighTotal,
                name: "薪资8k以上",
                itemStyle: {
                  // 设置扇形的颜色
                  color: "#e8b110"
                }
              },
              {
                value: this.employmentNormalTotal,
                name: "薪资8k以下",
                itemStyle: {
                  // 设置扇形的颜色
                  color: "#6fb3e0"
                }
              }
            ]
          }
        ]
      };

      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
      window.addEventListener("resize", () => {
        //浏览器大小调整echarts随之改变
        myChart.resize();
      });
    },
    initBar() {
      // 基于准备好的dom，初始化echarts实例
      var myChart = this.$echarts.init(this.$refs.zhaosheng);

      // 指定图表的配置项和数据
      var option = {
        xAxis: {
          data: this.preMonthNum.map(item => item.monthFormat),
          axisLabel: {
            show: true,
            textStyle: {
              color: "#fff"
            }
          }
        },
        tooltip: {},
        yAxis: {
          axisLabel: {
            show: true,
            textStyle: {
              color: "#fff"
            }
          }
        },
        series: [
          {
            name: "招生量",
            type: "bar",
            data: this.preMonthNum.map(item => item.total)
          }
        ],
        itemStyle: {
          color: "#01f6f8"
        }
      };

      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
      window.addEventListener("resize", () => {
        //浏览器大小调整echarts随之改变
        myChart.resize();
      });
    },
    initProvince() {
      myChart = this.$echarts.init(this.$refs.provinceCanvas);
      myChart.showLoading();

      axios
        .get("/pc/geoJson/" + this.provinceCode + ".geoJson")
        .then(response => {
          myChart.hideLoading();
          let data = response.data;
          let mapJsonList = data.features;
          let mapJson = {};
          let mapData = [];
          for (let i in mapJsonList) {
            mapData.push({
              name: mapJsonList[i].properties.name,
              adcode: mapJsonList[i].properties.adcode,
              value: Math.random() * 100000,
              selected: i == 0 ? true : false
            });
          }
          this.cityCode = mapJsonList[0].properties.adcode;
          this.getMarketData();
          this.getEmployData();

          mapJson.features = mapJsonList;
          this.mapJson = mapJson;
          this.mapData = mapData;
          this.loadMap("province", mapJson, mapData); //加载地图数据
        })
        .catch(function(error) {
          // handle error
          console.log(error);
        });
    },
    loadMap(mapName, mapJson, mapData) {
      if (mapJson) {
        this.$echarts.registerMap(mapName, mapJson);
        var option = {
          visualMap: {
            //在展示每个区域数据量场景使用，不同的范围区域颜色调节
            min: 50000,
            max: 800,
            inRange: {
              color: ["#003095", "#2e7ac4", "#4060c6"]
            },
            text: ["High", "Low"], // 文本，默认为数值文本
            calculable: false,
            show: false
          },
          series: [
            {
              type: "map",
              mapType: "province", // 自定义扩展图表类型
              label: {
                show: true,
                color: "#fff"
              },
              data: mapData,
              itemStyle: {
                emphasis: {
                  //鼠标移入高亮显颜色
                  areaColor: "#f50d34"
                }
              }
            }
          ]
        };
        myChart.setOption(option);

        window.addEventListener("resize", () => {
          //浏览器大小调整echarts随之改变
          myChart.resize();
        });
        myChart.on("click", params => {
          //地图区域点击时的联动   根据params的值来联动对应数据
          console.log(params); //此处写点击事件内容
          for (var i = 0; i < this.mapData.length; i++) {
            this.mapData[i].selected = false;
            if (params.name == this.mapData[i].name) {
              this.mapData[i].selected = true;
              this.cityCode = this.mapData[i].adcode;
              this.getMarketData();
              this.getEmployData();
            }
          }
          myChart.setOption(option);
        });
      }
    },
    getMarketData() {
      axios
        .get("/api/area/getNumByCode?adcode=" + this.cityCode)
        .then(res => {
          res = res.data;
          this.countries = res.data.list;
          // new BScroll(this.$refs.country);

          // this.$refs.baomingAddressContent.style.width =
          //   5.8 * (this.countries.length + 1) + "vw";
          // this.$nextTick(() => {
          //   new BScroll(this.$refs.baomingAddress, {
          //     startX: 0,
          //     click: true,
          //     scrollX: true,
          //     scrollY: false,
          //     eventPassthrough: "vertical",
          //   });
          // });

          this.countryCode = this.countries[0].adcode;
          this.studentNumTotal = res.data.studentAtNumTotal;
          this.employmentHighTotal = res.data.employmentHighTotal;
          this.employmentNormalTotal = res.data.employmentNormalTotal;
          this.preMonthNum = res.data.preMonthNum;
          this.marketNumTotal = res.data.marketNumTotal;

          this.getBaomingData();
          this.initPie();
          this.initBar();
        })
        .catch(function(error) {
          // handle error
          console.log(error);
        });
    },
    getBaomingData() {
      axios
        .get("/api/area/getMarketByCode?adcode=" + this.countryCode)
        .then(res => {
          res = res.data;
          this.baomingAddress = res.data;
        })
        .catch(function(error) {
          // handle error
          console.log(error);
        });
    },
    getEmployData() {
      axios
        .get("/api/job/getJobList?limit=50&adcode=" + this.cityCode)
        .then(res => {
          res = res.data;
          this.emploies = res.data;
          this.$nextTick(() => {
            this.swiper && this.swiper.destroy();
            this.swiper = new Swiper(".swiper-container", {
              slidesPerView: 5,
              spaceBetween: 30,
              direction: "vertical", // 垂直切换选项
              autoplay: {
                delay: 800
              }, // 自动播放
              loop: true // 循环模式选项
            });
          });
        })
        .catch(function(error) {
          // handle error
          console.log(error);
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.box {
  background: url(../../../src/assets/usian/bg_statistics.jpg) center no-repeat;
  background-size: cover;
  text-align: left;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

header {
  width: 100%;
  height: 4.17vw;
  background: url(../../../src/assets/usian/header_province.png) center
    no-repeat;
  background-size: 100%;
  position: relative;
}

.rolling {
  height: 100%;
  ul.animation {
    animation: moving 40s linear infinite;
  }
  /*定义动画*/
  @keyframes moving {
    from {
      transform: translateY(0);
    }
    to {
      transform: translateY(-50%);
    }
  }
}

.arrow-left {
  position: absolute;
  top: 0;
  left: 40px;
  width: auto;
  height: 2.08vw;
  transform: rotate(180deg);
}

.arrow-right {
  position: absolute;
  top: 0;
  right: 40px;
  width: auto;
  height: 2.08vw;
}

.main {
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
  flex: 1;
}

.empty {
  width: 100%;
  height: 8vw;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: #ffffff;
}

.center {
  flex: 1;
  background: url(../../../src/assets/usian/bg_province.png) center bottom
    no-repeat;
  background-size: 150% auto;
}

.left,
.right {
  width: 29.17vw;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.section-box {
  width: 24.17vw;
  height: 12.92vw;
  background: url(../../../src/assets/usian/bg_psection.png) center no-repeat;
  background-size: 100%;
  padding: 1.8vw 2.5vw;
}

.section {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.swiper {
  overflow: hidden;
}

.title {
  text-align: center;
  font-size: 18px;
  color: #18f9c6;
  position: relative;
  top: 10px;
}

.item > h5 {
  text-align: center;
  font-size: 14px;
  color: #ffffff;
  margin-bottom: 5px;
}

.item {
  width: 5vw;
  display: inline-block;
  margin: 0 0.4vw 0.62vw 0.4vw;
}

.bg-country {
  background: url(../../../src/assets/usian/bg_pitem.png) center no-repeat;
  background-size: 100%;
}

.bg-baoming {
  background: url(../../../src/assets/usian/bg_baoming.png) center no-repeat;
  background-size: 100%;
}

.item-bg {
  width: 5.21vw;
  height: 4.38vw;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  h6 {
    color: #3affe4;
    font-size: 30px;
  }

  p {
    position: absolute;
    bottom: 0;
    font-size: 14px;
    color: #ffffff;
  }
}

.xibao {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #ffffff;
  height: 2.59vw;
}

.xibao > span {
  flex: 1;
  margin-right: 20px;
  text-align: left;
}

.xibao > .corp,
.xibao > .date {
  flex: 2;
}

.swiper-content {
  margin-top: 20px;
}

.c-item {
  display: flex;
  font-size: 16px;
  color: #ababb0;
  margin-bottom: 10px;
}

.c-item > span {
  flex: 1;
  text-align: left;
}

.renshu {
  display: flex;
  justify-content: space-between;
}

.s-item {
  display: flex;
  justify-content: center;
  align-items: center;
}

.s-item > img {
  margin-right: 20px;
}

.renshu h3 {
  font-size: 18px;
  color: #ababb0;
}

.renshu h5 {
  font-size: 40px;
  color: #3affe4;
}

@media screen and (max-width: 1366px) {
  .renshu h3 {
    font-size: 12px;
    color: #ababb0;
  }

  .renshu h5 {
    font-size: 24px;
    color: #3affe4;
  }
}
</style>
